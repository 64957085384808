function isInViewport(element) {
  const rect = element.getBoundingClientRect();

  // entrou na tela
  // rect+top > 0 && rect.top <= rect.height
  // saindo da tela
  // rect.height + rect.top < -1*rect.height
  // saiu da tela
  // rect.height + rect.top > -1*rect.height

  return (
    rect.height + rect.top >= 0 &&
    rect.height + rect.top < 2.1 * rect.height
  );
}

function fixtop() {
  const top = document.querySelector(".top.flex");
  const topRect = top.getBoundingClientRect();

  if (window.pageYOffset > topRect.height) {
    top.classList.add("fixed");
  } else {
    top.classList.remove("fixed");
  }
}

//if (window.innerWidth < window.innerHeight) {
  window.addEventListener("scroll", fixtop);
//}


function updateMenu() {
  const sections = document.querySelectorAll('[data-menu]');
  const menuItems = document.querySelectorAll('.menu li.active');
  const menuMobItems = document.querySelectorAll('.menu-items li.active');
  let menuItem, menuMobItem;

  menuItems.forEach( (el) => el.classList.remove('active') );
  menuMobItems.forEach( (el) => el.classList.remove('active') );

  sections.forEach( (el) => {
    if (isInViewport(el)) {
      menuItem = document.getElementById(`menu-${el.dataset.menu}`);
      if (menuItem && menuItem.classList.contains('enabled')) {
        menuItem.classList.add('active');
      }

      menuMobItem = document.getElementById(`menu-mob--${el.dataset.menu}`);
      if (menuMobItem && menuMobItem.classList.contains('enabled')) {
        menuMobItem.classList.add('active');
      }
    }
  } );
}

window.addEventListener('scroll', updateMenu);

const menuItems = document.querySelectorAll('.menu.float .menu-item');
menuItems.forEach( (el) => {
  el.addEventListener('click', (e) => {
    e.preventDefault();
    const sectionId = e.target.parentElement.id.substring(5)
    document.querySelector(`[data-menu="${sectionId}"]`).scrollIntoView({behavior: 'smooth'});
  });
})

const mobMenuItems = document.querySelectorAll('.mobile .menu-item');
mobMenuItems.forEach( (el) => {
  el.addEventListener('click', (e) => {
    e.preventDefault();
    let sectionId = e.target.parentElement.id.substring(10)
    if( sectionId = 'estado') {
      sectionId = 'video';
    }

    document.querySelector(`[data-menu="${sectionId}"]`).scrollIntoView({behavior: 'smooth'});
    document.querySelector('.nav-container input').checked = false;
  });
})

const newsLink = document.querySelector('.footer .news');
newsLink.addEventListener('click', (e) => {
  e.preventDefault();
  document.querySelector(`[data-menu="quem-somos"]`).scrollIntoView({behavior: 'smooth'});
});

const sobreLink = document.querySelector('.menu .sobre');
sobreLink.addEventListener('click', (e) => {
  e.preventDefault();
  document.querySelector(`[data-menu="quem-somos"]`).scrollIntoView({
    behavior: 'smooth',
    block: document.querySelector('.top').classList.contains('fixed') ? 'end' : 'start',
  });
});


